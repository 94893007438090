import _nodeGypBuild from "./node-gyp-build.js";
import _process from "process";
var exports = {};
var process = _process;
if (typeof process.addon === "function") {
  // if the platform supports native resolving prefer that
  exports = process.addon.bind(process);
} else {
  // else use the runtime version here
  exports = _nodeGypBuild;
}
export default exports;